import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import StaticSection from "../components/StaticSection"
import Contact from "../components/Contact"

const Content = styled.section`
  padding: ${props => (props.itemBelow ? "32px 16px 0" : "32px 16px 64px")};
  & h4 {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.3px;
    color: #062964;
    line-height: 30px;
    padding-bottom: 8px;
    position: relative;
    font-weight: 900;
    margin-bottom: 16px;
  }

  & ul {
    list-style: initial;
    padding-left: 24px;
    & li {
      font-size: 20px;
      line-height: 32px;
      color: #3a465b;
      &:first-child {
        margin-top: 6px;
      }
    }
  }
  & .section-desc {
    line-height: 25px;
    letter-spacing: 0.3px;
    color: #3a465b;
  }
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;
    padding: 0 16px;
    align-self: center;
    grid-area: 3 / 1 / 3 / 3;
    & .section-desc {
      font-size: 20px;
      line-height: 32px;
      margin-top: 16px;
    }
  }
`

const UszkodzonyPojazd = () => {
  const { imageMobile, imagePc } = useStaticQuery(graphql`
    query {
      imageMobile: file(relativePath: { eq: "uszkodzony-pojazdMobile.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imagePc: file(relativePath: { eq: "UszkodzonyPojazdPC.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <StaticSection
        title="Lorem ipsum dolor sit"
        desc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat ex
        cupiditate qui vitae animi odit rem aut atque architecto porro amet,"
        img={imageMobile}
        imgPc={imagePc}
        buttonText="Wyślij e-mail"
        link="mailto:lorem@ipsum.pl"
        sectionTitle="Lorem ipsum dolor sit"
        sectionDesc={
          <>
            <p className="section-desc">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat
              ex cupiditate qui vitae animi odit rem aut atque architecto porro
              amet, dolorem a dicta perferendis illum maiores voluptatum
              perspiciatis harum?
            </p>
            <p className="section-desc">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat
              ex cupiditate qui vitae animi odit rem aut atque architecto porro
              amet, dolorem a dicta perferendis illum maiores voluptatum
              perspiciatis harum?
            </p>
          </>
        }
        listItems={[
          <p>
            <span> Lorem ipsum</span> dolor sit
          </p>,
          <p>Lorem ipsum dolor sit</p>,
          <p>
            Lorem ipsum <span>dolor sit</span>
          </p>,
        ]}
        listButton="Wyślij e-mail"
        listButtonHref="mailto:lorem@lorem.pl"
        additionalSection={
          <Content>
            <div>
              <p className="section-desc">
                e qui vitae animi odit rem aut atque architecto porro amet,
                dolorem a dicta perferendis illum maiores voluptatum
                perspiciatis harum?
              </p>
              <p className="section-desc">
                e qui vitae animi odit rem aut atque architecto porro amet,
                dolorem a dicta perferendis illum maiores voluptatum
                perspiciatis harum?
              </p>
            </div>
            <div>
              <p className="section-desc" style={{ marginTop: 16 }}>
                e qui vitae animi odit rem aut atque architecto porro amet,
                dolorem a <strong>e qui vitae</strong> illum maiores voluptatum
                perspiciatis harum?
              </p>
              <p className="section-desc">
                e qui vitae animi odit rem aut atque architecto porro amet,
                dolorem a dicta perferendis illum maiores voluptatum
                perspiciatis harum?
              </p>
            </div>
          </Content>
        }
      />
      <Contact />
    </Layout>
  )
}

export default UszkodzonyPojazd
