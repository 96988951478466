import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { StyledButton } from "./styledButton"
import { StyledList } from "./Advantages"
import { StyledTitle } from "./Title"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import check from "../images/icons/contact/check.svg"

const StyledSection = styled.section`
  margin-top: 110px;
  @media (min-width: 768px) {
    max-width: 576px;
    margin: 130px auto 0;
  }
  @media (min-width: 1024px) {
    max-width: 1200px;
    margin: 180px auto 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 100px;
    grid-column-gap: 50px;
    grid-template-areas:
      "headInfo imageSection"
      "contentSection itemsSection";
  }
  @media (min-width: 1400px) {
    grid-column-gap: 100px;
  }
`

const HeadInfo = styled.div`
  padding: 0 16px;
  margin-bottom: 16px;
  & .title {
    color: #062964;
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 0.1px;
    margin-bottom: 16px;
    font-weight: 900;
  }
  & .desc {
    line-height: 25px;
    letter-spacing: 0.3px;
    color: #3a465b;
  }
  & .pc-hero-btn {
    display: none;
  }
  @media (min-width: 1024px) {
    grid-area: headInfo;
    align-self: center;
    & .title {
      font-size: 48px;
      line-height: normal;
      letter-spacing: 0.1px;
      color: #062964;
      margin-bottom: 34px;
    }
    & .desc {
      padding-left: 30px;
      position: relative;
      width: 90%;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.3px;
      margin-bottom: 34px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 2px;
        border-radius: 1px;
        background-color: #fff;
        height: 100%;
      }
    }
    & .pc-hero-btn {
      display: block;
      text-align: center;
      width: fit-content;
      padding: 13px 46px;
    }
  }
`

const Items = styled.div`
  padding: 16px 16px 32px;
  background-color: #fff;
  position: relative;
  z-index: 5;
  & .listItems-title {
    color: #062964;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.3px;
  }
  @media (min-width: 1024px) {
    grid-area: itemsSection;
    padding: 20px 30px;
    height: fit-content;
    align-self: center;
    border-radius: 4px;
    z-index: auto;
    min-width: 350px;
    max-width: 410px;
    justify-self: flex-end;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background-image: linear-gradient(to left, #195bc5 100%, #196fd9);
      top: 0;
      right: 0;
      transform: translate(10px, 10px);
      z-index: -10;
    }
    & li {
      & p {
        & span {
          z-index: 20;
          &::after {
            z-index: 10;
          }
        }
      }
    }
    & .listItems-title {
      font-size: 24px;
      line-height: 32px;
    }
  }
`

const ExtendStyledList = styled(StyledList)`
  @media (min-width: 1024px) {
    margin-top: 20px;
    margin-bottom: 0;
    & li {
      margin-bottom: 20px;
      & p {
        line-height: 24px;
        margin-left: 16px;
      }
    }
  }
`

const ItemsButton = styled(StyledButton)`
  width: -webkit-fill-available;
  text-align: center;
  @media (min-width: 1024px) {
    display: none;
  }
`

const StyledImage = styled(Img)`
  margin: 32px 16px 0;
  &.pc-img {
    display: none;
  }
  @media (min-width: 1024px) {
    &.mobile-img {
      display: none;
    }
    &.pc-img {
      display: block;
      justify-self: flex-end;
      margin: 0;
    }
  }
`

const Images = styled.div`
  @media (min-width: 1024px) {
    grid-area: imageSection;
  }
`

const Content = styled.div`
  padding: ${props => (props.itemBelow ? "32px 16px 0" : "32px 16px 64px")};
  & ul {
    list-style: initial;
    padding-left: 24px;
    & li {
      font-size: 20px;
      line-height: 32px;
      color: #3a465b;
      &:first-child {
        margin-top: 6px;
      }
    }
  }
  & .section-desc {
    line-height: 25px;
    letter-spacing: 0.3px;
    color: #3a465b;
    margin-top: 16px;
  }
  @media (min-width: 1024px) {
    grid-area: contentSection;
    padding: 0 16px;
    align-self: center;
    & .section-desc {
      font-size: 20px;
      line-height: 32px;
    }
  }
`

const ExtendStyledTitle = styled(StyledTitle)`
  font-weight: 900;
  margin-bottom: 16px;
`

const StaticSection = ({
  title,
  desc,
  img,
  imgPc,
  buttonText,
  link,
  sectionTitle,
  sectionDesc,
  listTitle,
  listItems,
  listButton,
  listButtonHref,
  itemBelow,
  additionalSection,
}) => {
  return (
    <StyledSection>
      <HeadInfo>
        <h1 className="title">{title}</h1>
        <p className="desc">{desc}</p>
        {buttonText === "Kontakt" ? (
          <StyledButton as={AnchorLink} to={link} className="pc-hero-btn">
            {buttonText}
          </StyledButton>
        ) : (
          <StyledButton as="a" href={link} className="pc-hero-btn">
            {buttonText}
          </StyledButton>
        )}
      </HeadInfo>
      <Items>
        <h3 className="listItems-title">{listTitle}</h3>
        <ExtendStyledList>
          {listItems.map((item, i) => (
            <li key={i}>
              <img src={check} alt="check icon" />
              {item}
            </li>
          ))}
        </ExtendStyledList>
        {listButton === "Kontakt" ? (
          <ItemsButton as={AnchorLink} to={listButtonHref}>
            {listButton}
          </ItemsButton>
        ) : (
          <ItemsButton as="a" href={listButtonHref}>
            {listButton}
          </ItemsButton>
        )}
      </Items>
      <Images>
        <StyledImage
          fluid={img.childImageSharp.fluid}
          alt={title}
          className="mobile-img"
        />
        <StyledImage
          fluid={imgPc.childImageSharp.fluid}
          alt={title}
          className="pc-img"
        />
      </Images>
      <Content itemBelow={itemBelow}>
        <ExtendStyledTitle>{sectionTitle}</ExtendStyledTitle>{" "}
        {typeof sectionDesc === "object" ? (
          sectionDesc
        ) : (
          <p className="section-desc">{sectionDesc}</p>
        )}
      </Content>
      {additionalSection}
    </StyledSection>
  )
}

export default StaticSection
